.mainContainer{
    width: 100vw;
    height: 100vh;
    background-image: url("https://res.cloudinary.com/de2od3piw/image/upload/v1669144052/pics/background_yloyzt.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.imageCenter img{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.box {
    top: 30%;
    left: 45%;
    position: absolute;
    justify-content: center;
    width: 300px;
    height: 300px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    overflow: hidden;
  
  }
  
  .box::before {
    content: "";
    position: absolute;
    inset: -10px 60px;
    background: linear-gradient(315deg, #00ccff, #d400d4);
    transition: 0.5s;
    animation: animate 4s linear infinite;
  }
  
  .box:hover::before {
    inset: -20px 0px;
  }
  
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .box::after {
    content: "";
    position: absolute;
    inset: 6px;
    background: #162052;
    backdrop-filter: blur(2px);
    border-radius: 50%;
    z-index: 1;
  }
  
  .content {
    position: absolute;
    inset: 5px;
  
    z-index: 3;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .content img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
    pointer-events: none;
    z-index: 3;
  }
  
  .box:hover .content img {
    opacity: 0;
  }
  
  .content h2 {
    position: relative;
    font-size: 1.2em;
    text-align: center;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  
  .content h2 span {
    font-weight: 300;
    font-size: 0.75em;
  }
  
  .content a {
    position: relative;
    margin-top: 10px;
    padding: 10px 20px;
    background: #fff;
    color: #070a1c;
    border-radius: 25px;
    font-weight: 500;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    text-decoration: none;
    transition: 0.5s;
  }
  
  .content a:hover {
    letter-spacing: 0.2em;
  }
  
  .mainImage img{
    width: 500px;
    height: 500px;
  }

  .mainContainerOpacity{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }