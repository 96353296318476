@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

.mainContainer{
    color: var(--textColor);
    font-weight: 600;
    padding: 24px;
    border-bottom: 1px solid var(--textColor);
    backdrop-filter: blur(8px);
}

.containerGeneral{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.containerGeneral p{
    margin: 0px 20px 0px 20px;
    padding: 0px;
}

.containerNav{
    margin-left: 50%;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    
}

.unselected{
    color: var(--textColor);
    text-decoration: none;
    font-size: 15px;
}

.unselected:hover{
    font-size: 17px;
}

.avatarName{
    color: var(--main-color);
    font-size: 22px;
    font-family: Courgette;
}

.selected{
    color: var(--textColor);
    text-decoration: none;
}