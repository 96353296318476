body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --main-color: #f3c035;
  --textColor: #faebd7;
  --cardColor: rgb(20, 19, 19);
  --bgColor: rgb(17, 16, 16);
}

::-webkit-scrollbar {
  display: none;
}

html{
  scroll-behavior: smooth;
}

.mainContainerOpacity{
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}


