.mainContainer{
    height: 90%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--textColor);
}

.mainContainer h1, .mainContainer h2, .mainContainer h3{
   margin:0px 0px 10px 0px;
    
}

.mainContainer h2{
    margin-top: 10px;
     
 }

.subtitle{
    color: var(--main-color);
    font-size: 22px;
    font-family: Courgette;
}
 
