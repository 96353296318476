.mainContainer{
    min-height: 100vh;
    padding-top: 50px;    
    display: flex;
    align-items: space-evenly;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bgColor);
    color:var(--textColor);
}



.cardContainer{
    padding: 20px 80px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.filterContainer{
    width: 100%;
    background-color: var(--bgColor);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0px;
}

.filter{
    width: 20%;
    background-color: var(--textColor);
    color: var(--bgColor);
    margin-right: 20px;
}