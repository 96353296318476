.mainContainer{
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 2fr; 
    grid-template-rows: 1fr; 
    gap: 0px 40px;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 10px;
    background-color: rgb(20, 19, 19);
    box-shadow: -2px 6px 10px 2px rgba(0,0,0,0.49);
    -webkit-box-shadow: -2px 6px 10px 2px rgba(0,0,0,0.49);
    -moz-box-shadow: -2px 6px 10px 2px rgba(0,0,0,0.49);
}

.containerNames{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.containerBars{
    align-items: center;
    padding-top: 12%;
}

.skillsIcons{
    font-size: 20px;
    padding: 5px 0px 0px 0px;

}

.skillName{
    color: var(--main-color);
}