.footerContainer{  
    display: flex;
    align-items: space-evenly;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bgColor);
    color:var(--textColor);
}

.footerContainer h5{  
    margin:0px auto 10px;
    padding: 0px auto 10px;
}

.socialContainer{
    margin: 10px auto 0px;
    width: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    
}

.icons , .iconsInstagram{
    color: antiquewhite;
    padding: 10px 14px;
    border-radius: 100%;
    transition: background-color 0.4s;
}

.icons:hover , .iconsInstagram:hover{
    background-color: rgb(24, 26, 26);
    box-shadow: -2px 6px 10px 2px rgba(0,0,0,0.49);
    -webkit-box-shadow: -2px 6px 10px 2px rgba(0,0,0,0.49);
    -moz-box-shadow: -2px 6px 10px 2px rgba(0,0,0,0.49);
}

.iconsInstagram{
    font-size: 22px;
}

.avatarName{
    color: var(--main-color);
    font-size: 15px;
    font-family: Courgette;
}
