.card{
    height: 350px;
    width: 22%;
    backdrop-filter: blur(5px);
    background-color: rgba(0,0,0,0.30);
    border-radius: 15px;
    padding: 15px;
    box-shadow: -2px 6px 10px 2px rgba(0,0,0,0.49);
    -webkit-box-shadow: -2px 6px 10px 2px rgba(0,0,0,0.49);
    -moz-box-shadow: -2px 6px 10px 2px rgba(0,0,0,0.49);
}

.ArchivementIcon{
    display: flex;
    justify-content: center;
    margin: 10px auto ;
    width: fit-content;
    padding: 8px;
    border-radius: 100%;
    border: 2px solid var(--main-color);
    
}

.ArchivementTitle{
    font-size: 24px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ArchivementSubitle{
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 400;
}

.ArchivementParagraph{
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    hyphens: auto;
    
}

.date{
    font-size: 14px;
    font-weight: 400;
    align-self: flex-end;
}