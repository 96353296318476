.mainContainer{
    padding-top: 50px;
    display: flex;
    align-items: space-evenly;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bgColor);
    color: var(--textColor);
}

.profileContainer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
   
}

.profileContainer img{
   height: 400px;
   border-radius: 5%;
   border: 3px solid var(--main-color);
}

.textProfileContainer{
    max-width: 40%;
}

.textProfileContainer{
    text-align: justify;

}

.skillsContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin: 36px 0px;
}

.skillsContainer h4{
    margin: 12px 0px;
}

.skills{
    width: 40%;
}